import Webcam from 'react-webcam';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { MobileFullscreen } from 'react-mobile-fullscreen';
import { Col, Modal, Row } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { useRef, useState } from 'react';

const Mask = (props) => {
  return (
    <div
      style={{
        background: "white",
        width: "100%",
        height: "100%",
      }}
    >
      {props.fullscreenType === "native"
        ? "Toque para iniciar"
        : props.fullscreenType === "minimal-ui"
          ? "Arraste para cima para iniciar"
          : "Mask won't be rendered"}
    </div>
  );
};

function App() {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null);
  const webcamRef = useRef();

  const clicked = (e) => {
    if (!loading) {
      setLoading(true);
      const imageSrc = webcamRef.current.getScreenshot({ width: 1080, height: 1920 });
      fetch('/api/upload', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          image: imageSrc.substr(23)
        })
      }).then((data) => {
        return data.json();
      }).then((json) => {
        setUrl(json.url);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
        setUrl(null);
      });
    } else {
      setLoading(false);
      setUrl(null);
    }
  };

  return (
    <MobileFullscreen mask={Mask}>
      <div className="App" onClick={clicked}>
        <Webcam ref={webcamRef} width="100%" videoConstraints={{ facingMode: 'environment', width: 1920, height: 1080, aspectRatio: 1.777777778 }} screenshotFormat="image/jpeg" screenshotQuality={0.9} />
        <div className='overlay'><img className='overlay' src='overlay.png' /></div>
        {!loading ?
          <Row>
            <Col>&nbsp;</Col>
            <Col>
              <img height="130px" src='camera.png' />
            </Col>
            <Col>&nbsp;</Col>
          </Row>
          :
          <div className='pane'>
            {url ?
              <div className='qrbox'>
                <QRCode className='qrcode' value={url} />
              </div>
              :
              <img className='loading' src='loading.svg' />
            }
          </div>
        }
      </div>
    </MobileFullscreen>
  );
}

export default App;
